html {
    overscroll-behavior-x: contain!important;
}

.App {
/*    background-color: #dcdcdc;*/
    background-color: #151d1e;
    min-height: 100vh;
    text-align: center;
    overflow-x: scroll;
    overflow-y: scroll;
}

:root {
    --background-primary: #3b4042;
    --background-secondary: #292e30;
    --background-tertiary: #151d1e;
    --background-translucent: hsla(0,0%,50%,.1);
    --background-hover: hsla(0,0%,50%,.125);
    --background-active: hsla(0,0%,50%,.25);
    --background-top: #0d1215;
    --button-primary: #6eb0ab;
    --button-primary-hover: #539792;
    --button-primary-active: #47827d;
    --button-secondary: #6e8ab0;
    --button-secondary-hover: #5878a1;
    --button-secondary-active: #476182;
    --button-danger: #d86152;
    --button-danger-hover: #aa4c3f;
    --button-danger-active: #8b3d32;
    --avatar-color0: #6e89b0;
    --avatar-color1: #7d6daf;
    --avatar-color2: #b06e8a;
    --avatar-color3: #6eb0aa;
    --avatar-color4: #a2c03c;
    --text-header: #fff;
    --text-normal: #dcddde;
    --text-link: #6eb0ab;
    --text-link-active: #80bab5;
    --text-danger: #d86152;
    --text-secondary: #d1d1d1;
    --text-muted: #7d7d7d;
    --status-online: #6ce0c7;
    --status-offline: #919191;
    --status-idle: #e3ca71;
    --status-dnd: #d86152;
    --scrollbar-track: rgba(0,0,0,.2);
    --message-entry-field: #4d5254;
    --text-input-color: rgba(0,0,0,.2);
    --text-input-border: transparent;
    --text-input-active: rgba(0,0,0,.3);
    --text-input-hover: rgba(0,0,0,.4);
    --message-hover: rgba(0,0,0,.1);
    --check-active: rgba(0,0,0,.1);
    --check-mark: rgba(0,0,0,.75);
    --drop-shadow-small: 0 4px 4px rgba(0,0,0,.16);
    --drop-shadow-large: 0 8px 16px rgba(0,0,0,.24);
    --font-primary: "Poppins";
    --font-code: monospace;
}

@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(./fonts/ad19729d6a0511212493.woff2) format("woff2")}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(./fonts/3c3f3613b59d581739fc.woff2) format("woff2")}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(./fonts/dd24d10e037690f2bdcd.woff2) format("woff2")}

a,abbr,acronym,address,applet,big,blockquote,body,caption,cite,code,dd,del,dfn,div,dl,dt,em,fieldset,form,h1,h2,h3,h4,h5,h6,html,iframe,img,ins,kbd,label,legend,li,object,ol,p,pre,q,s,samp,small,span,strike,strong,table,tbody,td,tfoot,th,thead,tr,tt,ul,var{border:0;font-family:inherit;font-size:100%;font-style:inherit;font-weight:inherit;margin:0;padding:0;vertical-align:baseline}
button,input,option,select{-webkit-appearance:none;-moz-appearance:none;appearance:none}
img{-webkit-user-drag:none}
body,html{text-rendering:optimizelegibility;font-family:var(--font-primary);height:100%;position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:100%}
body{box-sizing:border-box;margin:0;overflow:scroll;padding:0}

input{background-color:var(--text-input-color);border:1px solid var(--text-input-border);border-radius:8px;box-sizing:border-box;color:var(--text-normal);font-size:16px;outline:0;padding:12px 10px;position:relative;transition:border-color .2s ease-in-out;width:100%}
input.no-select{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
input:hover{border-color:var(--text-input-active)}
input:focus{border-color:var(--text-link)}
input.error{border-color:var(--text-danger)}
input.icon{padding-left:36px}
input::-moz-placeholder{color:var(--text-muted);font-size:.9em}
input:-ms-input-placeholder{color:var(--text-muted);font-size:.9em}
input::placeholder{color:var(--text-muted);font-size:.9em}

button{background-color:var(--button-primary);border:none;border-radius:4px;color:var(--text-header);height:38px;min-width:96px;outline:none;padding:8px 12px}
button:disabled{cursor:not-allowed}
button:not(:disabled){cursor:pointer}
button:hover:not(:disabled){background-color:var(--button-primary-hover)}
button:active:not(:disabled){background-color:var(--button-primary-active)}
button.large{height:auto;padding:16px 20px}
button.secondary{background-color:var(--button-secondary)}
button.secondary:hover:not(:disabled){background-color:var(--button-secondary-hover)}
button.secondary:active:not(:disabled){background-color:var(--button-secondary-active)}
button.transparent{background-color:var(--background-hover)}
button.transparent:active:not(:disabled),button.transparent:hover:not(:disabled){background-color:var(--background-active)}
button.grow{width:100%}

.header{color:var(--text-normal);font-size:14px}
.authentication{align-items:center;background-color:var(--background-secondary);display:flex;flex-direction:column;height:100%;justify-content:space-evenly;width:100%}
.logo{width:100px}
.logo img{width:100%}
.route{display:grid}
.login{align-items:center;background-color:var(--background-primary);border-radius:25px;display:flex;grid-column:1/2;grid-row:1/2;justify-content:center;width:300px}
.container{padding:10px 20px;width:100%}
.title{color:var(--text-header);font-size:26px;margin-bottom:30px}
.app-mount{text-rendering:optimizelegibility;background-color:var(--background-secondary);font-family:var(--font-primary);height:100%;min-height:100vh;overflow:scroll;overflow-y:scroll;width:100%}

.fw-bold{font-weight:700}
.fw-600{font-weight:600}
.fw-700{font-weight:700}
.text-upper{text-transform:uppercase}
.mt-4{margin-top:4px}
.mt-8{margin-top:8px}
.mt-12{margin-top:12px}
.mt-20{margin-top:20px}
.mb-4{margin-bottom:4px}
.mb-8{margin-bottom:8px}
.mb-12{margin-bottom:12px}
.mb-20{margin-bottom:20px}
.m-auto{margin-left:auto;margin-right:auto}

#link-username {
  color: #31837a;
  font-weight: bold;
  font-size: 14px;
}

#link-provider {
  color: #31837a;
  font-weight: bold;
  font-size: 14px;
}

